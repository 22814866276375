import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';

import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';
import {Col, Row, Button, Space} from 'antd/lib';
import {DeleteOutlined} from '@ant-design/icons';
import {LocaleContext} from '~src/context';
import {navigate} from 'gatsby';
import {Routes} from '~src/utils/routes';

interface IContentProps {}

const Content: React.FC<IContentProps> = () => {
  const {dispatch, articles} = useStoreon<IState, IEvents>('articles');

  const {
    pages: {
      admin: {
        articles: {addButton, editButton, deleteButton},
      },
    },
  } = useContext(LocaleContext);

  const [selectedArticleID, setSelectedArticleID] = useState(0);

  useEffect(() => {
    dispatch('articles/fetch-articles', {strategy: 'overwrite'});
  }, []);

  useEffect(() => {
    articles?.values[0]?.id && setSelectedArticleID(articles.values[0].id);
  }, [articles]);

  const handleClickAtArticle = (id: number | undefined) => {
    id && setSelectedArticleID(id);
  };

  const handleClickAtAddButton = () => {
    navigate(Routes.articleModeration);
  };
  const handleClickAtEditButton = (id: number) => {
    if (id > 0) navigate(Routes.articleModeration + `?id=${id}`);
  };

  const handleClickAtDeleteButton = (id: number) => {
    if (id > 0) dispatch('articles/delete-article', {id});
  };

  return (
    <Row gutter={[0, 20]}>
      <Col span={24}>
        <Row gutter={20}>
          {articles.values.map(article => (
            <ColStyled
              xs={24}
              sm={12}
              md={8}
              lg={6}
              key={article.id}
              onClick={() => handleClickAtArticle(article.id)}
              isactive={selectedArticleID === article.id ? 1 : 0}
            >
              <Row>
                <ImageWrapperColStyled>
                  <ImageStyled src={article.previewUrl} />
                </ImageWrapperColStyled>
                <Col span={24}>
                  <DateStyled>{article.creationTime}</DateStyled>
                  <HeaderStyled>{article.title}</HeaderStyled>
                </Col>
              </Row>
            </ColStyled>
          ))}
        </Row>
      </Col>

      <Col span={24}>
        <Space>
          <Button disabled={selectedArticleID < 1} onClick={() => handleClickAtDeleteButton(selectedArticleID)}>
            <DeleteOutlined />
            {deleteButton}
          </Button>
          <Button
            type={'primary'}
            disabled={selectedArticleID < 1}
            onClick={() => handleClickAtEditButton(selectedArticleID)}
          >
            {editButton}
          </Button>
        </Space>
      </Col>

      <SpaceStyled span={24} />

      <Col span={24}>
        <Row justify={'space-between'}>
          <Col>
            <Space>
              <Button type={'primary'} onClick={handleClickAtAddButton}>
                {addButton}
              </Button>
            </Space>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const ColStyled = styled(Col)<{isactive: number}>`
  border: ${props => (props.isactive ? '1px solid red' : '1px solid white')};
  border-radius: 10px;
`;

const SpaceStyled = styled(Col)`
  height: 50px;
`;

const ImageWrapperColStyled = styled(Col)`
  position: relative;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`;

const ImageStyled = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  background: linear-gradient(180deg, rgba(24, 144, 255, 0.35) 0%, rgba(24, 144, 255, 0.35) 100%);
  min-width: 100px;
  min-height: 100px;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  border-radius: 10px;
`;

const DateStyled = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 170%;
  color: #666;
`;

const HeaderStyled = styled.h2`
  word-break: break-word;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
  text-transform: uppercase;
`;

export default Content;
