import React, {useContext} from 'react';
import Providers from '~src/components/Providers';
import {AdminTemplate} from '~src/components';
import Content from '~src/components/organisms/AdminOrganism/Articles/Content';
import {LocaleContext} from '~src/context';

interface IStatsPageProps {}

const ArticlesPage: React.FC<IStatsPageProps> = () => {
  const {
    pages: {admin},
  } = useContext(LocaleContext);
  return (
    <Providers>
      <AdminTemplate pageTitle={admin.articles.title}>
        <Content />
      </AdminTemplate>
    </Providers>
  );
};

export default ArticlesPage;
